import clsx from "clsx";
import styles from "./Heading.module.css";

interface HeadingProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children: string | React.ReactNode;
  className?: string;
  centered?: boolean;
}

function Heading({
  variant = "h1",
  children,
  className,
  centered = false,
}: HeadingProps) {
  const Tag = variant;

  return (
    <Tag
      className={clsx([styles[variant], centered && "text-center", className])}
    >
      {children}
    </Tag>
  );
}

export default Heading;
